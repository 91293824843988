// actions.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from "firebase/compat/app";
import { collection, query, where, onSnapshot } from 'firebase/firestore';

import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface Applicant {
  id: string;
  recruitId: string;
  name: string;
  uid: string;
  examination: number;
  sex: string;
  time: string;
  managerMemo: string;
  hopeStore: string[];
  examList1: string[];
  examList2: string[];
  examList3: string[];
  examList4: string[];
  examList5: string[];
}

interface ApplicantsState {
  applicantsListTrue: Applicant[];
  applicantsListFalse: Applicant[];
  progressCount: number[];
}

const initialState: ApplicantsState = {
  applicantsListTrue: [],
  applicantsListFalse: [],
  progressCount: [],
};

interface FetchManageApplicantsPayload {
  applicantsListTrue: Applicant[];
  applicantsListFalse: Applicant[];
  progressCount: number[];
}

interface getList {
  uid: string;
  ids: string[];
}

interface FetchManageResponse {
  applicantsListTrue: Applicant[];
  applicantsListFalse: Applicant[];
  progressCount: number[];
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = firebase.initializeApp(firebaseConfig);

// Cloud Functions の `fetchManageApplicants` を呼び出す
export const fetchManageApplicants = createAsyncThunk(
  "manageApplicants/fetchManageApplicants",
  async ({ companyId, uid } : { companyId: string; uid: string }, { rejectWithValue }) => {
    try {
      const functions = getFunctions(app, "asia-northeast1"); // Cloud Functions を取得
      const fetchApplicants = httpsCallable(functions, "fetchManageApplicants");
      const response = await fetchApplicants({ companyId, uid });

      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue("An unknown error occurred");
      }
    }
  }
);

// Redux スライス（状態管理）
const manageApplicantsSlice = createSlice({
  name: "manageApplicants",
  initialState: {
    list: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchManageApplicants.fulfilled, (state, action) => {
        state.list = action.payload as {};
      })
  },
});

export default manageApplicantsSlice.reducer;
