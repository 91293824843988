import Styles from './styles.module.css';
import 'firebase/auth'
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import DefaultTitle from '../../package/parts/title/index';
import DefaultImage from './../../image/default/home.png';
import Mail from './mail/index';
import { FaPencilAlt } from 'react-icons/fa';
import Invite from './invite/index';
import DefaultImg from './../../image/default/account.png';
import Edit from './edit/index';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import DefaultInput1 from '../../package/parts/1-input/index';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyState } from 'src/features/0.main/company';

const Setting: React.FC = () => {

  const dispatch = useDispatch();

  const company = useSelector((state: any) => state.company.company);
  const users = useSelector((state: any) => state.auth.users);

  const [ name, setName ] = useState(company.name);
  const [ invite, setInvite ] = useState(false);
  const [ mail, setMail ] = useState(false);
  const [ banner, setBanner ] = useState(false);

  const [ styleImageFile, setStyleImageFile ] = useState('');
  const [ styleImageFilePath, setStyleImageFilePath ] = useState<File | null>(null);

  const [ member, setMember ] = useState<userList[]>([]);

  const [ manageId, setManageId ] = useState('');

  useEffect(() => {
    getAuthority(company.member);
  }, []);

  interface userList {
    id: string;
    name: string;
  }
  
  function getAuthority(data: string[]) {
    const docRef = collection(firebase.firestore(), 'users')
    const queryRef = query(docRef, where("user_uid", "in", data))
    const unsub = onSnapshot(queryRef, snapshot => {
      var tmp: userList[] = [];
      snapshot.docs.forEach(doc => {
        tmp.push({ id: doc.id, name: doc.data().name })
      });
      setMember(tmp);
    })
    return () => unsub();
  }

  const styleImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setStyleImageFile(URL.createObjectURL(e.target.files[0]));
      setStyleImageFilePath(e.target.files[0]);
    }
  }

  function save() {
    setBanner(true);

    firebase.firestore().collection('companies').doc(company.id)
    .update({
      name: name,
    });

    if (styleImageFilePath) {
      firebase.storage().ref().child(`/companies_logo/${company.id}`).put(styleImageFilePath);
    }

    firebase.firestore().collection('recruits').where('companyId', '==', company.id).get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
        firebase.firestore().collection('recruits').doc(doc.id)
        .update({
          companyName: name,
        });
      });
    });

    const companyId = localStorage.getItem('companyId') || "";
    dispatch(fetchCompanyState(companyId));

    setTimeout(() => {
      setBanner(false);
    }, 1500);
  }
  
  return (
    <>
    <div className={Styles.box}>
      <div className={Styles.box_inline}>
        <div className={Styles.contents}>
          <h5 className={Styles.title}>基本情報</h5>

          <DefaultTitle title={'会社ロゴ'} required={false} />
          <div className={Styles.make_image}>
            <FaPencilAlt className={Styles.make_image_pen} />
            <input type='file' onChange={styleImages} />
            {styleImageFile === '' ?
              <img className={Styles.make_image_file}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${company.id}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultImage;
              }}/>
              :
              <img className={Styles.make_image_file} src={styleImageFile} />
            }
          </div>

          <DefaultInput1
            title={'企業名'} required={true} length={20} place={'memorii 株式会社'} type={'text'}
            value={name} setValue={setName}
            desc={'　'}
          />

          <button className={Styles.save} onClick={() => save()}>保存</button>
        </div>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>メンバー管理</h5>
          <div className={Styles.member_title}>
            <p>メンバー</p>
            <h4>権限</h4>
          </div>

          {member.map((data, index) =>
            <div className={Styles.account} key={index}>
              <div className={Styles.account_user}>
                <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${data.id}_200x200?alt=media&token=?${new Date().getTime()}`}
                  onError={e => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = DefaultImg;
                  }}/>
                <h4>{data.name}</h4>
              </div>
              <div className={Styles.account_authority}>
                {company.administrator.includes(data.id) ? '管理者' : '審査員' }
              </div>
              <FaPencilAlt onClick={() => setManageId(data.id)} className={Styles.account_icon} />
            </div>
          )}

          <div className={Styles.invite} onClick={() => setInvite(true)}>
            <FaPencilAlt className={Styles.invite_icons} />
            <p>メンバーを招待</p>
          </div>

          <div style={{height: '10px'}}></div>

          <div className={Styles.invite_btn}>
            <p>招待中アカウント</p>
          </div>

          {company.invite.length !== 0 ?
          <>
            <div className={Styles.invite_list}>
              {company.invite.map((data: string) =>
                <p>{data}</p>
              )}
            </div>
          </> : <></> }
        </div>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>メール通知設定</h5>

          <div className={Styles.account}>
          
            <div className={Styles.account_user}>
              <h4>通知先アドレス</h4>
            </div>

            <div className={Styles.account_notification}>
              <p>{company.mails === undefined ? "未登録": company.mails}</p>
              <FaPencilAlt onClick={() => setMail(true)} className={Styles.account_icon} />
            </div>
            
          </div>

          <div style={{height : '30px'}}></div>

          <h5 className={Styles.title}>LINE通知設定</h5>

          <div className={Styles.account}>
            {company.lineId === undefined ?
              <>
              <div className={Styles.account_user}>
                <img src={`https://linestep.jp/wp-content/uploads/2022/04/linelogo.png`}
                  onError={e => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = DefaultImg;
                  }}/>
                <h4>連携</h4>
              </div>

              <a href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2006500323&redirect_uri=https%3A%2F%2Fmemorii-works.com%2Fservice%2Fcallback&state=${company.id}&scope=openid%20profile&nonce=SWFEng3EK6E3HNKyIOa-YT8NW5QIrtqEjYpY2sZoLMI`}
                className={Styles.account_alignment}>
                <div>
                  LINEと連携する
                </div>
              </a>
              </>
              :
              <>
              <div className={Styles.account_user}>
                <img src={company.linePicture}
                  onError={e => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = DefaultImg;
                  }}/>
                <h4>{company.lineName}</h4>
              </div>

              <a href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2006500323&redirect_uri=https%3A%2F%2Fmemorii-works.com%2Fservice%2Fcallback&state=${company.id}&scope=openid%20profile&nonce=SWFEng3EK6E3HNKyIOa-YT8NW5QIrtqEjYpY2sZoLMI`}
                className={Styles.account_alignment}>
                <div>
                  LINEと連携済み
                </div>
              </a>
              </>
            }
          </div>

          <p className={Styles.desc_line}>※上記の連携が完了し次第、通知を行うための<a href='https://lin.ee/ApYAoTN'>公式LINE</a>を追加してください。</p>

          <a href='https://lin.ee/ApYAoTN'>
            <button className={Styles.btn_line}>通知用 公式LINE</button>
          </a>

        </div>
      </div>
    </div>

    {invite ?
      <Invite setInvite={setInvite} />
    : <></> }

    {mail ?
      <Mail mailAddress={company.mails === undefined ? "" : company.mails} setMail={setMail} />
    : <></> }

    {manageId !== '' ?
      <Edit manageId={manageId} setManageId={setManageId} checks={company.administrator.length === 1} />
    : <></> }

    {banner ?
      <div className={Styles.banner}>
        保存しました。
      </div> : <></>
    }
    </>
  );
}

export default Setting;