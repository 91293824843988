import authStyles from './styles.module.css';
import inputStyles from './input.module.css';
import firebase from 'firebase/compat/app';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../header/index';
import { FaEye } from "react-icons/fa";
import Modal from '../../package/parts/pop-modal/index';
import ErrorMessage from '../../package/parts/error/index';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyState } from '../../features/0.main/company';
import { getFunctions, httpsCallable } from "firebase/functions";

const AuthLogin: React.FC = () => {
   
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ errorAuth, setErrorAuth ] = useState(false);
  const [ errorId, setErrorId ] = useState(false);
  const [ errorNoneId, setErrorNoneId ] = useState(false);

  const [ worksId, setWorksId ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  const [ passwordEye, setPasswordEye ] = useState(false);

  function check() {
    setErrorAuth(false);
    setErrorId(false);
    setErrorNoneId(false);

    if (worksId === '') {
      setErrorMessage('会員IDを入力してください。');
    } else if (email.length <= 7) {
      setErrorMessage('メールアドレスを入力してください。');
    } else if (password.length <= 7) {
      setErrorMessage('パスワードを7文字以上入力してください。');
    } else {
      Register();
    }
  }

  const Register = async () => {
    await firebase.auth().signInWithEmailAndPassword(email, password)
    .then(() => {
      success(email);
    })
    .catch((error) => {
      console.log('エラーが出ているよ');
      setErrorAuth(true);
    });
  };

  async function success(email: string) {
    await firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.emailVerified) {
          companyAuth(user.uid, email);
        } else {
          navigate('/company/confirm');
        }
      }
    });
  }

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
    
  const app = firebase.initializeApp(firebaseConfig);

  interface FetchRecruitsResponse {
    docId: string;
  }
  
  async function companyAuth(uid: string, email: string) {
    const checkLoginAuth = async () => {
      const functionsForRegion = getFunctions(app, "asia-northeast1");
      const checkLoginAuth = httpsCallable<unknown, FetchRecruitsResponse>(functionsForRegion, "checkLoginAuth");
      try {
        const result = await checkLoginAuth({ worksId: worksId, uid: uid, email: email });
        if (result.data.docId === '') {
          setErrorNoneId(true);
        } else {
          localStorage.setItem('companyId', result.data.docId);
          localStorage.setItem('uid', uid);

          setErrorNoneId(false);
          dispatch(fetchCompanyState(result.data.docId));
          navigate(`/service`);
        }
      } catch (error) {
        console.error("Error calling Cloud Function:", error);
      }
    };
    checkLoginAuth();
  }

  return (
    <>
    <Header />

    <div className={authStyles.background}>

      {errorMessage !== '' ?
        <ErrorMessage message={errorMessage} />
      : <></> }

      <div className={authStyles.contents}>

        <h2 className={authStyles.contents_title}>ログイン</h2>

        <p className={inputStyles.input_title}>会員ID</p>
        <div className={inputStyles.input_input}>
          <input placeholder='memorii' value={worksId} onChange={(e) => setWorksId(e.target.value)} />
        </div>
        
        <p className={inputStyles.input_title}>メールアドレス</p>
        <div className={inputStyles.input_input}>
          <input placeholder='memorii@trankllc.com' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        
        <p className={inputStyles.input_title}>パスワード</p>
        <div className={inputStyles.input_input}>
          <input placeholder='nk12q3ja' type={passwordEye ? '' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
          <FaEye className={inputStyles.input_icon} onClick={() => setPasswordEye(!passwordEye)} />
        </div>

        <button className={authStyles.contents_btn} onClick={() => check()}>ログインする</button>

        <p className={authStyles.contents_link} onClick={() => navigate('/company/signin')}>
          アカウントをお持ちではない方はこちら
        </p>

        <p className={authStyles.contents_link} onClick={() => navigate('/company/reset')}>
          パスワードをお忘れの方はこちら
        </p>

      </div>

    </div>

    {errorAuth ?
      <Modal 
        title={'ログイン エラー'}
        desc={'メールアドレス、もしくはパスワードが間違っています。\nまた、アカウント登録がお済みではない方は「アカウントをお持ちではない方はこちら」より、アカウントを作成してください。'}
        setError={setErrorAuth}
      />
    : <></> }

    {errorNoneId ?
      <Modal 
        title={'美容室ID エラー'}
        desc={'美容室IDが間違っています。\n美容室IDを確認したい方は「memorii 事務局」に美容室IDの発行を依頼してください。'}
        setError={setErrorNoneId}
      />
    : <></> }
    
    {errorId ?
      <Modal 
        title={'認証 エラー'}
        desc={'メールアドレス、パスワードの内容と会員IDが一致していません。ログインを再度試す、もしくは弊社までお問い合わせください。'}
        setError={setErrorId}
      />
    : <></> }
    </>
  );
}

export default AuthLogin;