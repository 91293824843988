import React from 'react';
import Styles from './styles.module.css';
import { useSelector } from 'react-redux';

type ManageNavProps = {
  choiceNav: number;
  setChoiceNav: React.Dispatch<React.SetStateAction<number>>;
  choiceRecruit: number;
  progressCount: number[];
};

const RecruitItem: React.FC<ManageNavProps> = ({ choiceNav, setChoiceNav, choiceRecruit, progressCount }) => {
  
  const recruitList = useSelector((state: any) => state.recruit.recruitList);
  const examSelection = recruitList[choiceRecruit]?.exam_selection;
  
  // const progressCount = useSelector((state: any) => state.manageApplicants.progressCount ?? [ 0, 0, 0, 0, 0, 0, 0, 0, ]);

  var sum = progressCount.reduce(function(total: any, currentValue: any) {
    return total + currentValue;
  }, 0);

  return (
    <>
    <div className={Styles.box}>
      <div>
        <h4 style={Number(choiceNav) === 1000 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} 
          onClick={() => setChoiceNav(1000)}>一覧({sum})</h4>

        {Number(choiceRecruit) === 1000 || (Number(choiceRecruit) !== 1000 && 1 <= examSelection && examSelection <= 5) ?
          <h4 style={Number(choiceNav) === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => setChoiceNav(1)}>1次選考({progressCount[1] ?? 0})</h4>
        : <></> }

        {Number(choiceRecruit) === 1000 || (Number(choiceRecruit) !== 1000 && 2 <= examSelection && examSelection <= 5) ?
          <h4 style={Number(choiceNav) === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => setChoiceNav(2)}>2次選考({progressCount[2] ?? 0})</h4>
        : <></> }

        {Number(choiceRecruit) === 1000 || (Number(choiceRecruit) !== 1000 && 3 <= examSelection && examSelection <= 5) ?
          <h4 style={Number(choiceNav) === 3 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => setChoiceNav(3)}>3次選考({progressCount[3] ?? 0})</h4>
        : <></> }

        {Number(choiceRecruit) === 1000 || (Number(choiceRecruit) !== 1000 && 4 <= examSelection && examSelection <= 5) ?
          <h4 style={Number(choiceNav) === 4 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => setChoiceNav(4)}>4次選考({progressCount[4] ?? 0})</h4>
        : <></> }

        {Number(choiceRecruit) === 1000 || (Number(choiceRecruit) !== 1000 && 5 <= examSelection && examSelection <= 5) ?
          <h4 style={Number(choiceNav) === 5 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => setChoiceNav(5)}>5次選考({progressCount[5] ?? 0})</h4>
        : <></> }

        <h4 style={Number(choiceNav) === 6 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNav(6)}>採用({progressCount[6] ?? 0})</h4>

        <h4 style={Number(choiceNav) === 7 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNav(7)}>不採用({progressCount[7] ?? 0})</h4>
      </div>
    </div>
  </>
  );
}

export default RecruitItem;
