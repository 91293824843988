import Styles from './styles.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'firebase/auth'
import Items from './items/index';
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchManageApplicants } from '../../features/manage/applicants';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from 'firebase-admin';

interface Applicant {
  id: string;
  recruitId: string;
  name: string;
  uid: string;
  examination: number;
  sex: string;
  time: string;
  managerMemo: string;
  hopeStore: string[];
  examList1: string[];
  examList2: string[];
  examList3: string[];
  examList4: string[];
  examList5: string[];
}

interface recruitList {
  id: string;
  status: number;
  title: string;
  employment: string;
  time: string;
  pv: number;
  saves: number;
  applicants: string[];
  recruitment: number;
  goal: number;
  apply: number;
}

const ManageMobile: React.FC = () => {
  
  const dispatch = useDispatch();

  const users = useSelector((state: any) => state.auth.users);
  const recruitList = useSelector((state: any) => state.recruit.recruitList as recruitList[]);
  const company = useSelector((state: any) => state.company.company);
  const recruitListOnlyId = useSelector((state: any) => state.recruit.recruitIds);

  const [ choiceRecruit, setChoiceRecruit ] = useState(1000);
  const [ choiceNav, setChoiceNav ] = useState(1000);

  const [ searchText, setSearchText ] = useState('');

  const [ applicantsListTrue, setApplicantsListTrue ] = useState([] as Applicant[]);
  const [ applicantsListFalse, setApplicantsListFalse ] = useState([] as Applicant[]);

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
  
  const app = firebase.initializeApp(firebaseConfig);

  interface FetchManageResponse {
    applicantsListTrue: Applicant[];
    applicantsListFalse: Applicant[];
    progressCount: number[];
  }

  useEffect(() => {
    const fetchApplicants = async () => {
      const functionsForRegion = getFunctions(app, "asia-northeast1");
      const fetchManageApplicants = httpsCallable<unknown, FetchManageResponse>(functionsForRegion, "fetchManageApplicants");

      try {
        const uid = localStorage.getItem('uid') || "";
        const companyId = localStorage.getItem('companyId') || "";
        console.log(companyId);
        const result = await fetchManageApplicants({ uid: uid, companyId: companyId });

        setApplicantsListTrue(result.data.applicantsListTrue as Applicant[]);
        setApplicantsListFalse(result.data.applicantsListFalse as Applicant[]);

        console.log(result.data as any);
      } catch (error) {
        console.error("Error calling Cloud Function:", error);
      }
    };
    fetchApplicants();
  }, []);


  const isAllIncludes = (arr: string[], target: string | any[]) => arr.some(el => target.includes(el));
  
  return (
    <>
    <div className={Styles.works_service_mobile}>
      <div className={Styles.box}>
        <div className={Styles.box_header}>
          <div className={Styles.box_header_search}>
            <select className={Styles.box_select_recruit}
              onChange={async (e) => {
                if (e.target.value === '')  {
                  setChoiceRecruit(1000);
                } else {
                  setChoiceRecruit(Number(e.target.value));
                }
              }}>
              <option value=''>全ての求人</option>
              {recruitList.map((data, index) =>
                <option value={index}>{data.title}</option>
              )}
            </select>
            <div>
              <FaSearch className={Styles.box_header_search_icon} />
              <input placeholder='応募者名 検索' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </div>
          </div>

          <div className={Styles.table_header}>
            <p className={Styles.table_header_1}>応募者名</p>
            <p className={Styles.table_header_2}>性別</p>
            <p className={Styles.table_header_3}>審査</p>
            <p className={Styles.table_header_4}>保存</p>
          </div>
        </div>

        {applicantsListTrue.map((data) =>
        <>
          {choiceRecruit === 1000 && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}
          </> )}

          {choiceRecruit !== 1000 && recruitList[choiceRecruit]?.id === data.recruitId && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}
          </>)}
        </> )}
        
        {applicantsListFalse.map((data) =>
        <>
          {choiceRecruit === 1000 && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}
          </> )}
          
          {choiceRecruit !== 1000 && recruitList[choiceRecruit]?.id === data.recruitId && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}
          </>)}

        </> )}

      </div>
    </div>
    </>
  );
}

export default ManageMobile;
