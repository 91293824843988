import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import Header from './0.header/index';
import Navigation from './navigation/index';
import Manage from './4.manage/index';
import Schedule from './5.schedule/index';
import Messages from './6.contact/index';
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import Details from "./info/index";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthState } from '../features/0.main/auth';
import { fetchCompanyState } from '../features/0.main/company';
import { fetchNavigation } from '../features/0.main/navigation';

const Service: React.FC = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const meta = {
    name: 'robots',
    content: 'noindex',
  };
  
  const company = useSelector((state: any) => state.company.company);
  const details = useSelector((state: any) => state.detailsPanel.details);
  const navigation = useSelector((state: any) => state.navigation.number);

  useEffect(() => {
    if (isMobile) {
      const fetchData = async () => {
        var fetchUsers =  await dispatch(fetchAuthState());
        if (fetchAuthState.fulfilled.match(fetchUsers)) {
          const users = fetchUsers.payload;

          const companyId = localStorage.getItem('companyId') || "デフォルトの文字列";

          var fetchCompany = await dispatch(fetchCompanyState(companyId));
          
          if (fetchCompanyState.fulfilled.match(fetchCompany)) {
            const company = fetchCompany.payload;
  
            if (Number(navigation) === 1 || Number(navigation) === 2 || Number(navigation) === 3 || Number(navigation) === 7) {
              dispatch(fetchNavigation(4));
            }
          }
        }
      }
      fetchData();
    }
  }, [ dispatch ]);

  return (
    <>
    {isMobile ?
    <>
      <Header />
      <Navigation navigation={navigation} />
      
      <div className={Styles.body}>
        {navigation === 4 ?
          <Manage />
        : navigation === 5 ?
          <Schedule />
        : navigation === 6 ?
          <Messages />
          : <></>
        }
      </div>


      {details ? 
        <Details /> : <></> }

    </> : <></> }
    </>
  );
}

export default Service;
