import Styles from './styles.module.css';
import firebase from 'firebase/compat/app';
import { useState } from 'react';
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa6";
import { BiMessageDetail } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { LuClipboardEdit } from "react-icons/lu";
import MemoPanel from "./memo/index";
import ExamPanel from "./examination/index";
import DefaultImg from './../../../image/default/account.png';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import Examination from "./../../info/evaluation/index";

import { fetchDetails } from '../../../features/package/detailsPanel';
import { fetchRecruit } from '../../../features/package/detailsPanel';
import { fetchApplicant } from '../../../features/package/detailsPanel';
import { fetchManageApplicants } from '../../../features/manage/applicants';

type ManageItemProps = {
  applicant: ApplicantList;
  save: boolean;
  navigation: number;
};

type ApplicantList = {
  id: string;
  recruitId: string;
  name: string;
  uid: string;
  examination: number;
  sex: string;
  time: string;
  managerMemo: string;
  hopeStore: string[];
  examList1: string[];
  examList2: string[];
  examList3: string[];
  examList4: string[];
  examList5: string[];
};

const ManageItem: React.FC<ManageItemProps> = ({ applicant, save }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector((state: any) => state.auth.users);
  const company = useSelector((state: any) => state.company.company);
  const recruitListOnlyId = useSelector((state: any) => state.recruit.recruitIds);

  const [ memo, setMemo ] = useState<string>('');
  const [ memoToggle, setMemoToggle ] = useState<boolean>(false);
  const [ examToggle, setExamToggle ] = useState<boolean>(false);
  const [ examPanelToggle, setExamPanelToggle ] = useState(false);

  const uid = localStorage.getItem('uid') || "";
  const companyId = localStorage.getItem('companyId') || "";

  return (
    <>
    <div className={Styles.box} >

      <div className={Styles.box_1}
        onClick={() => {
          dispatch(fetchDetails(true)); dispatch(fetchRecruit(applicant.recruitId)); dispatch(fetchApplicant(applicant.id));
        }}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${applicant.uid}_500x500?alt=media&token=`} alt=''
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultImg;
          }}/>
        <div className={Styles.box_1_post}>
          <div>
            <div style={{backgroundColor: '#192651'}}></div>
            <p>{applicant.examination === 1 ? '1次選考' : applicant.examination === 2 ? '2次選考' : applicant.examination === 3 ? '3次選考' : applicant.examination === 4 ? '4次選考' : applicant.examination === 5 ? '5次選考' : applicant.examination === 6 ? '採用' : '不採用'}</p>
          </div>
          <h2>{applicant.name}</h2>
        </div>
      </div>

      <p className={Styles.box_2}
        onClick={() => {dispatch(fetchDetails(true)); dispatch(fetchRecruit(applicant.recruitId)); dispatch(fetchApplicant(applicant.id))}}>
        {applicant.sex}性
      </p>

      <p className={Styles.box_3}
        onClick={() => {dispatch(fetchDetails(true)); dispatch(fetchRecruit(applicant.recruitId)); dispatch(fetchApplicant(applicant.id))}}>
        {new Date(applicant.time).getFullYear()}年
        {new Date(applicant.time).getMonth() + 1}月
        {new Date(applicant.time).getDate()}日
      </p>

      {/* <p className={Styles.box_5}
        onClick={() => {dispatch(fetchDetails(true)); dispatch(fetchRecruit(applicant.recruitId)); dispatch(fetchApplicant(applicant.id))}}>
        {applicant.hopeStore !== undefined ?
        <>
          {applicant.hopeStore[0]}
        </> : <></> }
      </p> */}

      <p className={Styles.box_4} onClick={() => {setMemo(applicant.managerMemo); setMemoToggle(true);}}>
        {applicant.managerMemo}
      </p>

      {applicant.examination === 5 && !applicant.examList5.includes(uid) && applicant.examList5.length < 8 ?
        <LuClipboardEdit className={Styles.box_6} style={{color : '#222222'}} onClick={() => setExamToggle(true)} />
      : applicant.examination === 4 && !applicant.examList4.includes(uid) && applicant.examList4.length < 8 ?
        <LuClipboardEdit className={Styles.box_6} style={{color : '#222222'}} onClick={() => setExamToggle(true)} />
      : applicant.examination === 3 && !applicant.examList3.includes(uid) && applicant.examList3.length < 8 ?
        <LuClipboardEdit className={Styles.box_6} style={{color : '#222222'}} onClick={() => setExamToggle(true)} />
      : applicant.examination === 2 && !applicant.examList2.includes(uid) && applicant.examList2.length < 8 ?
        <LuClipboardEdit className={Styles.box_6} style={{color : '#222222'}} onClick={() => setExamToggle(true)} />
      : applicant.examination === 1 && !applicant.examList1.includes(uid) && applicant.examList1.length < 8 ?
        <LuClipboardEdit className={Styles.box_6} style={{color : '#222222'}} onClick={() => setExamToggle(true)} />
      : <></> }

      {save ?
        <FaBookmark className={Styles.box_6} style={{color : '#AD0200'}}
          onClick={() => {
            firebase.firestore().collection('applicants').doc(applicant.id)
              .update({save: firebase.firestore.FieldValue.arrayRemove(uid)});
            dispatch(fetchManageApplicants({ companyId: companyId, uid: uid }));
          }}
        />
      : <></> }

      {!save ?
        <FaRegBookmark className={Styles.box_6} style={{color : '#222222'}}
          onClick={() => {
            firebase.firestore().collection('applicants').doc(applicant.id)
              .update({save: firebase.firestore.FieldValue.arrayUnion(uid)});
            dispatch(fetchManageApplicants({ companyId: companyId, uid: uid }));
          }}
        />
      : <></> }

      {company.administrator.includes(uid) ?
        <BiMessageDetail className={Styles.box_7}
          onClick={() => {navigate(`/service/id=${company.id}+page=6+search=${applicant.name}`);}}
        />
      : <></> }

    </div>

    {memoToggle ?
      <MemoPanel id={applicant.id} name={applicant.name} memo={memo} setMemoToggle={setMemoToggle} />
    : <></> }

    {examToggle ?
      <ExamPanel setExamToggle={setExamToggle} setExamPanelToggle={setExamPanelToggle} />
    : <></> }

    {examPanelToggle ?
      <Examination applicant={applicant} setExamPanelToggle={setExamPanelToggle} />
    : <></> }
    </>
  );
}

export default ManageItem;
