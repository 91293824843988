import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import firebase from "firebase/compat/app";

export const fetchAuthState = createAsyncThunk(
  'auth/fetchAuthState',
  async (_, { rejectWithValue }) => {
    const auth = getAuth();

    return new Promise<Users>((resolve, reject) => {
      onAuthStateChanged(auth,
        async (user) => {
          if (user) {
            try {
              const doc = await firebase.firestore().collection('users').doc(user.uid).get();

              if (doc.exists) {
                const data = doc.data();
                resolve({
                  id: doc.id,
                  uid: user.uid,
                  name: data?.user_name || '',
                  lineId: data?.user_lineId,
                  linePicture: data?.user_linePicture,
                  lineName: data?.user_lineName,
                });
              } else {

                firebase.firestore().collection('users').doc(user.uid)
                .set({
                  user_name: '',
                  user_like_count : 0,
                  user_award : 0,
                  user_likes : [],
                  user_text : '',
                  user_uid : user.uid,
                  user_createdAt : new Date(),
                  user_scout : false,
                  user_address : [],
                  user_address_postalCode: [],
                  user_badge: 0,
                  user_business: "美容師",
                  user_recruit: false,
                  user_token: "",
                  user_connection : [],
                  user_connection_count : 0,
                  user_connection_permission : [],
                  user_connection_request : [],
                  user_likes_list : [],
                  user_login : '',
                  user_login_count : [],
                  user_notification : [],
                  user_notification_confiram : false,
                  user_post_like_count : 0,
                  user_post_view_count : 0,
                  user_views : '',
                  user_post_count : 0,
                  user_recruit_save: [],
                  user_instagram : '',
                  user_tiktok : '',
                  memorii_score : 0,
                })
                .then((result) => {
                  resolve({
                    id: doc.id,
                    uid: user.uid,
                    name: '',
                    lineId: '',
                    linePicture: '',
                    lineName: '',
                  });
                });
              }
            } catch (error) {
              reject(error);
            }
          } else {
            reject(rejectWithValue('User is not authenticated'));
          }
        },
        (error) => rejectWithValue(error.message)
      );
    });
  }
);

interface UserState {
  users: Users | '';
}

interface Users {
  id: string;
  uid: string;
  name: string;
  lineId: string;
  linePicture: string;
  lineName: string;
}

const initialState: UserState = {
  users: '',
};

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthState.fulfilled, (state, action) => {
        state.users = action.payload;
      })
  },
});

export default authSlice.reducer;
