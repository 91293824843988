import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Pages404: React.FC = () => {

  const navigate = useNavigate();

  const link: string = new URL(window.location.href).href;
  // const recruitLink = decodeURIComponent(link.slice(link.indexOf('http://localhost:3000') + 21, link.length));
  const recruitLink = decodeURIComponent(link.slice(link.indexOf('https://memorii-works.com') + 25, link.length));

  useEffect(() => {
    console.log(link + '：');
    console.log(recruitLink + '：');
    if (recruitLink === '/') {
      navigate('/search=');
    } else {
      navigate('/');
    }
  }, [recruitLink]);
  

  return (
    <>
    <h4 style={{fontSize: '30px', textAlign: 'center'}}>404 ページ</h4>
    </>
  );
}

export default Pages404;
