import Styles from './styles.module.css';
import React from 'react';

const RecruitFooter: React.FC = () => {
  
  return (
    <>
    <div className={Styles.footer}>
      <div className={Styles.footer_bottom}>
        <div>
          <a href='https://memorii-works.com/policy-service' rel="nofollow noopener noreferrer">
            <p>プライバシーポリシー</p>
          </a>
          <a href='https://memorii-works.com/terms-service' rel="nofollow noopener noreferrer">
            <p>利用規約</p>
          </a>
        </div>
        <p>© Trankllc. All Rights Reserved.</p>
      </div>
    </div>
    </>
  );
}

export default RecruitFooter;
