import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect } from 'react';
import Header from "./header/index";
import Filter from "./search/index";
import Recruits from "./recruits/index";
import Footer from "./footer";

const Top: React.FC = () => {
  
  const [ characterScore, setCharacterScore] = useState<number[]>([ 1, 1, 1 ]);

  const [ hashTagList, setHashTagList ] = useState<string[]>([]);
  const [ pref, setPref ] = useState("");
  const [ salonType, setSalonType ] = useState("");

  var tags = [ ...hashTagList, pref, salonType ];
  var filterTags = tags.filter(item => item !== '');
  var tagString = filterTags.map((data) => `${data}、`).join('');
  var changeString = tagString.slice(0, - 1);

  const meta = {
    title: filterTags.length !== 0 ? `${changeString}の美容師の新卒・アシスタント 求人` :
      'memorii works（メモリーワークス） | 美容業界専門の新卒マッチングサービス',
    description: '美容室へ就職される美容学生へ向けて行きたい美容室が見つかる就活サイト、美容学生を採用したい方へ向けて新卒採用・管理が行えるサービス',
    canonical: 'https://memorii-works.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Header setCharacterScore={setCharacterScore} />
      <Filter 
        hashTagList={hashTagList} setHashTagList={setHashTagList}
        pref={pref} setPref={setPref}
        salonType={salonType} setSalonType={setSalonType}
        />
      <Recruits characterScore={characterScore} />
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default Top;
