import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from "firebase/compat/app";

// 認証状態を監視するThunk
export const fetchCompanyState = createAsyncThunk(
  'company/fetchCompanyState',
  async (id: string, { rejectWithValue }) => {
    try {
      const doc = await firebase.firestore().collection('companies').doc(id).get();
      const companyDataList: companyData[] = [];
      const data = doc.data()!;
      
      if (doc.exists) {
        companyDataList.push({
          id: doc.id,
          name: data?.name || '',
          administrator: data?.administrator || [],
          member: data?.member || [],
          recruits: data?.recruits || [],
          mails: data?.notification_mails || '',
          invite: data?.invite || [],
          lineId: data?.user_lineId || '',
          linePicture: data?.user_linePicture || '',
          lineName: data?.user_lineName || '',
        });
      }
      return companyDataList[0] || [];
    } catch (error) {
      return rejectWithValue('Error fetching recruits');
    }
  }
);


interface companyData {
  id: string;
  name: string;
  administrator: string[];
  member: string[];
  recruits: string[];
  mails: string;
  invite: string[];
  lineId: string;
  linePicture: string;
  lineName: string;
}

interface CompanyState {
  company: companyData | [];
}

const initialState: CompanyState = {
  company: [],
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyState.fulfilled, (state, action) => {
        state.company = action.payload;
      })
  },
});

export default companySlice.reducer;
