import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import firebase from "firebase/compat/app";

export const fetchTasks = createAsyncThunk(
  'tasks/fetchTasks',
  async (companyId: string , { dispatch }) => {
    
    const today = new Date();
    const dateBase = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    const days = Array(7).fill(null).map((_, i) => new Date(dateBase.getFullYear(), dateBase.getMonth(), dateBase.getDate() + i + 1));
    
    dispatch(setDays(days));

    const docRef = collection(firebase.firestore(), 'applicants');
    const queryRef = query(docRef, where('companyId', '==', companyId));
    
    onSnapshot(queryRef, (snapshot) => {

      const counts = Array(7).fill(0);

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (!data || !data.examination1_schedule) return;

        const scheduleDates = [
          ...data.examination1_schedule,
          ...data.examination2_schedule,
          ...data.examination3_schedule,
          ...data.examination4_schedule,
          ...data.examination5_schedule,
        ]
        .map((schedule: any) => new Date(schedule.seconds * 1000));

        for (let i = 0; i < 7; i++) {
          const start = days[i];
          const end = new Date(start);
          end.setDate(end.getDate() + 1);

          scheduleDates.forEach((scheduleDate) => {
            if (start <= scheduleDate && scheduleDate < end) {
              counts[i]++;
            }
          });
        }
      });

      dispatch(setTasks(counts));
    });
  }
);

interface TaskState {
  tasks: number[];
  days: Date[];
}

const initialState: TaskState = {
  tasks: [],
  days: [new Date(), new Date(), new Date(), new Date(), new Date(), new Date(), new Date()],
};

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasks(state, action: PayloadAction<number[]>) {
      state.tasks = action.payload;
    },
    setDays(state, action: PayloadAction<Date[]>) {
      state.days = action.payload;
    },
  },
});

export const { setTasks, setDays } = taskSlice.actions;

export default taskSlice.reducer;
