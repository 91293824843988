import Styles from './styles.module.css';
import Items from './items/index';
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import Navigation from "./nav/index";
// import History from "../../package/info/pc/history/index";

import { fetchManageApplicants } from '../../features/manage/applicants';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from 'firebase-admin';

interface Applicant {
  id: string;
  recruitId: string;
  name: string;
  uid: string;
  examination: number;
  sex: string;
  time: string;
  managerMemo: string;
  hopeStore: string[];
  examList1: string[];
  examList2: string[];
  examList3: string[];
  examList4: string[];
  examList5: string[];
}

interface recruitList {
  id: string;
  status: number;
  title: string;
  employment: string;
  time: string;
  pv: number;
  saves: number;
  applicants: string[];
  recruitment: number;
  goal: number;
  apply: number;
}

const ManagePc: React.FC = () => {

  const dispatch = useDispatch();

  const users = useSelector((state: any) => state.auth.users);
  const recruitList = useSelector((state: any) => state.recruit.recruitList as recruitList[]);
  const company = useSelector((state: any) => state.company.company);
  const recruitListOnlyId = useSelector((state: any) => state.recruit.recruitIds);

  const [ choiceRecruit, setChoiceRecruit ] = useState(1000);
  const [ choiceNav, setChoiceNav ] = useState(1000);

  const [ searchText, setSearchText ] = useState('');

  const [ progressCount, setProgressCount ] = useState([] as number[]);
  const [ applicantsListTrue, setApplicantsListTrue ] = useState([] as Applicant[]);
  const [ applicantsListFalse, setApplicantsListFalse ] = useState([] as Applicant[]);

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
  
  const app = firebase.initializeApp(firebaseConfig);

  interface FetchManageResponse {
    applicantsListTrue: Applicant[];
    applicantsListFalse: Applicant[];
    progressCount: number[];
  }

  useEffect(() => {    
    // const fetchData = async () => {
      // await dispatch(fetchManageApplicants({ uid: users.uid, ids: recruitListOnlyId}));
    // }
    // fetchData();

    const fetchApplicants = async () => {
      const functionsForRegion = getFunctions(app, "asia-northeast1");
      const fetchManageApplicants = httpsCallable<unknown, FetchManageResponse>(functionsForRegion, "fetchManageApplicants");

      try {
        const uid = localStorage.getItem('uid') || "";
        const companyId = localStorage.getItem('companyId') || "";
        console.log(companyId);
        const result = await fetchManageApplicants({ companyId: companyId, uid: uid });

        setProgressCount(result.data.progressCount as number[]);
        setApplicantsListTrue(result.data.applicantsListTrue as Applicant[]);
        setApplicantsListFalse(result.data.applicantsListFalse as Applicant[]);
      } catch (error) {
        console.error("Error calling Cloud Function:", error);
      }
    };
    fetchApplicants();
  }, []);


  const isAllIncludes = (arr: string[], target: string | any[]) => arr.some(el => target.includes(el));

  return (
    <>
    <div className='works_service'>

      <Navigation 
        choiceNav={choiceNav} setChoiceNav={setChoiceNav}
        choiceRecruit={choiceRecruit}
        progressCount={progressCount}
      />

      <div className={Styles.manage}>
        
        <div className={Styles.manage_header}>
          <div className={Styles.manage_header_word}>
            <select 
              onChange={async (e) => {
                if (e.target.value === '')  {
                  setChoiceRecruit(1000);
                } else {
                  setChoiceRecruit(Number(e.target.value));
                }
              }}>
              <option value=''>全ての求人</option>
              {recruitList.map((data, index) =>
                <option value={index}>{data.title}</option>
              )}
            </select>
            <div>
              <FaSearch className={Styles.manage_header_word_icon} />
              <input placeholder='応募者名 検索' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </div>
          </div>
          <div className={Styles.manage_table}>
            <p className={Styles.manage_table_1}>応募者名</p>
            <p className={Styles.manage_table_2}>性別</p>
            <p className={Styles.manage_table_3}>応募日時</p>
            {/* <p className={Styles.manage_table_5}>希望店舗</p> */}
            <p className={Styles.manage_table_4}>メモ</p>
            <p className={Styles.manage_table_6}>審査</p>
            <p className={Styles.manage_table_6}>保存</p>
            {company.administrator && company?.administrator?.includes(users.uid) ?
              <p className={Styles.manage_table_7}>連絡</p>
            : <></> }
          </div>
        </div>

        {applicantsListTrue.map((data) =>
        <>
          {choiceRecruit === 1000 && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}
          </> )}

          {choiceRecruit !== 1000 && recruitList[choiceRecruit]?.id === data.recruitId && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={true} navigation={choiceNav} />
            )}
          </>)}
        </> )}
        
        {applicantsListFalse.map((data) =>
        <>
          {choiceRecruit === 1000 && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}
          </> )}
          
          {choiceRecruit !== 1000 && recruitList[choiceRecruit]?.id === data.recruitId && (
          <>
            {data.examination === choiceNav && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}

            {choiceNav === 1000 && isAllIncludes([searchText], data.name + data.managerMemo) && (
              <Items applicant={data} save={false} navigation={choiceNav} />
            )}
          </>)}
        </> )}

      </div>
    </div>

    {/* {history && recruitList.length !== 0 && applicantsList.length !== 0 ?
      <History recruitList={recruitList} applicantsList={applicantsList} setHistory={setHistory} />
    : <></> } */}
    </>
  );
}

export default ManagePc;
