import Styles from './styles.module.css';
import React from 'react';
import Icon from "./../../../../image/logo.png";
import { useNavigate } from 'react-router-dom';

const Terms: React.FC = () => {

  const navigate = useNavigate();

  return (
    <>
    <div className={Styles.nav}>
      <div className={Styles.nav_icon} onClick={() => navigate('/')}>
        <img src={Icon} alt='' />
        <p>memorii</p>
      </div>
      <div className={Styles.nav_text}>
        <p onClick={() => navigate('/student')}>美容学生</p>
        <p onClick={() => navigate('/company')}>事業者様</p>
        <p onClick={() => navigate('/contact')}>問い合わせ</p>
      </div>
    </div>
    <div className={Styles.policy}>
      <div>
        <h4>利用規約</h4>
        <p>
          <strong>第１条(総則)</strong><br />
          １．「memorii works」（以下「本アプリ」といいます。）とは、トランク合同会社（以下「当社」といいます。）が提供する、インターネット上の求人情報サイトおよび求職者応募管理サイト、その他メール配信サービス等当該サイトに付随するオンライン・オフラインでの各種情報提供サービスの総称をいいます。本利用規約（以下「本規約」といいます。）は、本アプリの提供条件及び本アプリをご利用いただくお客様と当社との間の権利義務関係を定めるものです。<br />
          ２．本アプリに関し、各サービスにおいて弊社が別途個別規約（以下「個別規約」といいます。）を定める場合、これらの個別規約は本規約の一部を構成するものとし、本規約と合わせて弊社と利用者との間の契約（以下「本契約」といいます。）の一部となります。また、本規約と各個別規約に相違が存在する場合、個別規約が優先して適用されます。<br />
          <br />
          <strong>第２条(利用者)</strong><br />
          １．「memorii works」の利用者(以下「利用者」といいます。)とは、本規約に同意した上で本アプリの申し込みを行い、弊社の承諾をもって本アプリを利用する企業・個人をいいます。<br />
          ２．利用者は、自らの意思および責任をもって本アプリを利用するものとします。<br />
          ３．当社は、登録申請者が以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあります。また、その理由について当社は一切の開示義務を負わないものとします。<br />
          ①当社に提供した登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合<br />
          ②本アプリと類似・競合するサービス若しくはウェブサイトを運営している又はその運営に協力していると当社が判断した場合<br />
          ③就職活動および採用活動に関連する活動以外の目的で活用し、本アプリの品位を著しく害する可能性があると当社が判断した場合<br />
          <br />
          <strong>第3条(利用料金)</strong><br />
          １．各サービスの利用料金は、個別規約に定められた内容に則るものとします。<br />
          ２．個別規約に利用料金の記載がない場合、原則無料で提供を行うものとします。<br />
          <br />
          <strong>第4条(利用者の責任)</strong><br />
          １．利用者は、本契約および個人情報保護方針(URL)に承諾の上、本アプリの利用を申し込むものとし、本アプリの申込をもって、本契約および個人情報保護方針を承諾したものとみなします。不承諾の意思表示は、本アプリを利用しないことをもってのみ認められるものとします。<br />
          ２．利用者は、自らの意思および責任をもって本アプリへの登録を行うものとします。<br />
          ３．利用者は、登録した情報の正確性・真実性・最新性等に、一切の責任を負うものとします。登録情報の不備・相違により生じた事項について、当社は一切の責任を負いません。<br />
          ４．インターネット上で本アプリを利用する際に必要となるインターネット回線等の環境・設備は利用者自身の責任と費用において用意するものであり、当社は本アプリのユーザビリティ・機能・完全性等において具体的な保証を行いません。<br />
          <br />
          <strong>第5条(利用者情報)</strong><br />
          １．利用者は、本アプリ利用時にメールアドレス・パスワード（以下「アカウント」といいます。）を登録する必要があります。その後、本アプリに当社が定めた情報を登録することで当該サービスの利用が可能となります。<br />
          ２．利用者は本アプリ内のマイページにおいて、登録した利用者情報を自由に変更・追加、削除することができます。<br />
          ３．利用者は、自己の責任において、本アプリに関するアカウントを適切に管理及び保管するものとし、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。<br />
          ４．アカウントの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は利用者が負うものとし、当社は一切の責任を負いません。<br />
          ５．アカウントの発行後に行われた当該アカウントによる本アプリの利用行為については、すべて利用者に帰属するものとみなします。<br />
          ６．利用者は、登録情報その他の情報を自らの責任において保存するものとし、当社は保存義務を負わないものとします。<br />
          <br />
          <strong>第6条(本アプリの変更・停止)</strong><br />
          １．当社は、利用者および第三者へ事前の通知を行うことなく、本アプリの変更または一時的な中断を行うことがあります。<br />
          ２．当社は1ヶ月間の予告期間をもって利用者に通知のうえ、利用者サービス全体の提供を長期的に中断もしくは終了することができます。<br />
          ３．当社は以下のいずれかに該当する場合には、利用者に事前に通知することなく、本アプリの全部又は一部の提供を停止又は中断できるものとします。<br />
          ①本アプリに係るコンピューター・システムの点検又は保守作業を緊急に行う場合<br />
          ②コンピューター又は通信回線等が事故により停止した場合<br />
          ③地震、落雷、火災、風水害、停電、疾病の蔓延、天災地変等の不可抗力により本アプリの運営ができなくなった場合<br />
          ④その他、当社が停止又は中断を必要と判断した場合<br />
          <br />
          <strong>第7条(当社の責任・免責事項)</strong><br />
          １．当社は、利用者または第三者が本アプリの利用により被ったいかなる損害に対しても、当社の責めに基づく場合を除き責任を負わないものとします。また、責任が当社に帰する場合でも、当社に故意または重大な過失がない限り、当社が責任を負う範囲は直接かつ現実に生じた通常の損害に限られるものとします。<br />
          ２．当社は、以下の各号に該当する事項により生じた損害について、当社に故意または重大な過失がない限り、一切の責任を負わないものとします。<br />
          ①通常講ずるべきウイルス対策では防止できないウイルス被害、天変地異による被害、予防措置を講じたにもかかわらず発生したシステム障害(以下、「不可抗力」といいます)による損害<br />
          ②不可抗力および不正アクセス等により生じた、システムの中断・遅滞・中止・データの消失等の損害<br />
          ③本アプリの利用により、利用者と第三者との間で生じた紛争等の損害<br />
          ④本規約に基づいた本アプリの変更・中断・終了等により発生した損害<br />
          ⑤その他当社の責によらない事由による損害<br />
          ３．当社に故意または重大な過失がある場合を除き、当社は利用者および第三者からの免責に関する個別の問い合わせや要望に対し、対応を拒否することがあります。また、その理由について当社は一切の開示義務を負わないものとします。<br />
          <br />
          <strong>第8条(情報の取り扱い)</strong><br />
          １．本アプリの個人情報に関しては、当社が別途定める「個人情報保護方針(URL)」および「プライバシーポリシー(URL)」に則り、適切な取得・利用・管理等を行います。
          ２．弊社は、利用者の登録情報および本アプリの利用記録から、個人を特定することができない統計データの作成・分析を行います。当該統計データは、本アプリのために弊社が利用できるほか、第三者に提供することができるものとし、利用者は予めこれを承諾するものとします。<br />
          ３．当社が前項の定めに則った統計データの提供により生じた事項に対し、弊社は一切の責任を負わないものとします。<br />
          <br />
          <strong>第９条（反社会的勢力の排除）</strong><br />
          １．利用者は、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。<br />
          ①暴力団員等が経営を支配していると認められる関係を有すること<br />
          ②暴力団員等が経営に実質的に関与していると認められる関係を有すること<br />
          ③自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること<br />
          ④暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること<br />
          ⑤役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること<br />
          <br />
          ２．利用者は、自らまたは第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします。<br />
          ①暴力的な要求行為<br />
          ②法的な責任を超えた不当な要求行為<br />
          ③取引に関して、脅迫的な言動をし、または暴力を用いる行為<br />
          ④風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為<br />
          ⑤その他前各号に準ずる行為<br />
          <br />
          <strong>第10条(サービス及び本規約の変更)</strong><br />
          １．本アプリおよび本規約は、利用者への事前の通知なくして、変更されることがあります。<br />
          ２．当社が本アプリおよび本規約の変更を行った後、利用者が本アプリを利用したことをもって、変更を承諾したものとみなします。<br />
          <br />
          <strong>第11条(業務委託)</strong><br />
          １．当社は、本アプリに関する業務の全部又は一部を業務に必要な範囲内で第三者に委託することができるものとします。<br />
          <br />
          <strong>第12条(会社分割による権利の譲渡)</strong><br />
          １．弊社が本アプリにかかる事業を他社に譲渡した場合は、当該事業譲渡に伴い本契約上の地位、本規約に基づく権利及び義務並びに利用者の情報等を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含みます。<br />
          <br />
          <strong>第13条(損害賠償)</strong><br />
          １．利用者は、本契約に違反して当社または第三者に直接かつ現実的に損害を与えた場合は、その損害を賠償するものとします。<br />
          <br />
          <strong>第14条(分離条項)</strong><br />
          １．本規約の一部の効力が、法令や確定判決により無効とされた場合であっても、その他の条項は引き続き効力を有するものとします。<br />
          <br />
          <strong>第15条(準拠法および管轄)</strong><br />
          １．本規約は日本法を準拠法はとし、本規約に関して生じる一切の紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的管轄裁判所とします。<br />
          <br />
          附則<br />
          2024年1月27日 適用<br />
        </p>
      </div>
    </div>
    <div className={Styles.footer}>
      <div className={Styles.footer_bottom}>
        <div>
          <a href='https://memorii-works.com/policy' rel="nofollow noopener noreferrer">
            <p>プライバシーポリシー</p>
          </a>
          <a href='https://memorii-works.com/terms' rel="nofollow noopener noreferrer">
            <p>利用規約</p>
          </a>
        </div>
        <p>© Trankllc. All Rights Reserved.</p>
      </div>
    </div>
    </>
  );
}

export default Terms;


